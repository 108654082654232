<template>
  <div id="order-calendar-main-page">
    <navigation></navigation>
    <div class="dashboard-container-right">
      <h2 class="title mt-4">Settings</h2>
      <hr />
      <div v-if="loading" class="loader-dashboard-page"><div></div></div>
      <div v-else class="form-wrapper mt-4" style="margin: 0 auto">
        <form ref="userEditForm" class="weblab-form" key="form" @submit.prevent="saveSettings()">
          <div style="max-width: 600px; margin: 0 auto">
            <!-- admin costs -->
            <div class="form-item required mt-4">
              <label>Admin Costs (AWG.)</label>
              <input
                :class="{ error: $v.settings.admin_costs.$error }"
                placeholder="eg. 25.00"
                autocorrect="none"
                v-model.trim="$v.settings.admin_costs.$model"
                type="number"
                size="60"
                maxlength="60"
                class="form-text"
              />
            </div>
            <!-- due days invoice -->
            <div class="form-item required mt-4">
              <label>Invoice due days</label>
              <input
                :class="{ error: $v.settings.invoice_due_days.$error }"
                placeholder="eg. 30"
                autocorrect="none"
                v-model.trim="$v.settings.invoice_due_days.$model"
                type="number"
                size="60"
                maxlength="60"
                class="form-text"
              />
            </div>

            <!-- contact info items -->
            <div v-for="(item, i) in $v.settings.invoice_contact_info.$each.$iter" :key="item.type">
              <div class="form-item required mt-4">
                <label>{{ settings.invoice_contact_info[i].label }}</label>
                <input
                  :class="{ error: item.info.$error }"
                  autocorrect="none"
                  v-model.trim="item.info.$model"
                  type="text"
                  size="60"
                  maxlength="60"
                  class="form-text"
                />
              </div>
            </div>
          </div>

          <!-- tems of conditions -->
          <div class="form-item required mt-4">
            <label>Terms and Agreements</label>
            <div class="panel-group" id="accordion" role="tablist">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingOne">
                  <h4 class="panel-title">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                      class="header-collapse-tab"
                    >
                      Click to expand
                    </a>
                  </h4>
                </div>
                <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                  <div class="panel-body">
                    <wysiwyg v-model.trim="$v.settings.terms_and_conditions.$model" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <input type="submit" id="edit-submit" name="op" value="Save" class="button js-form-submit form-submit mt-4" />
        </form>
        <p class="text-danger mt-2" v-if="$v.$anyError" style="font-weight: 600; font-size: 14px; float: right">
          Please fix any validation errors and try again.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from '@/components/generic/Navigation.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import * as helpers from '@/helpers/functions.js';
export default {
  mixins: [validationMixin],
  components: { Navigation },
  data() {
    return {
      hasFormValidationErrors: false,
      loading: false,
      settings: {
        admin_costs: null,
        invoice_due_days: null,
        terms_and_conditions: null,
        invoice_contact_info: [{ info: null }, { info: null }, { info: null }, { info: null }, { info: null }, { info: null }, { info: null }]
      }
    };
  },
  validations: {
    settings: {
      admin_costs: { required },
      invoice_due_days: { required },
      invoice_contact_info: {
        $each: {
          info: { required }
        }
      },
      terms_and_conditions: { required }
    }
  },
  async created() {
    this.getSettings();
  },
  methods: {
    async getSettings() {
      this.loading = true;
      await this.$store
        .dispatch('getInvoiceData')
        .then(data => {
          console.log(data);
          this.settings.admin_costs = parseFloat(data.admin_costs);
          this.settings.invoice_due_days = parseInt(data.invoice_due_days);
          this.settings.terms_and_conditions = data.terms_and_conditions;
          this.settings.invoice_contact_info = data.invoice_contact_info;
        })
        .finally(() => (this.loading = false));
    },
    saveSettings() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('updateSettings', this.settings)
        .then(() => {
          this.$snotify.success(`Settings saved.`, '', {
            timeout: 3000,
            showProgressBar: false,
            pauseOnHover: false,
            position: 'rightTop'
          });
        })
        .catch(error => helpers.displayErrorNoRetry(error, this))
        .finally(() => this.$store.commit('setTransLoader', false));
    }
  }
};
</script>

<style lang="scss">
@import '~vue-wysiwyg/dist/vueWysiwyg.css';
.editr {
  background: #fff;
}
</style>
